<template>
  <div class="background">
    <DocumentLines />
  </div>
</template>

<script>
import DocumentLines from "@/components/elements/dashboard/document-lines/DocumentLines";

export default {
  name: "DocumentManagementDocumentLines",

  components: {
    DocumentLines,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
