<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="documentLines"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="documentLinesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Document Lines</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <!-- <template
                  v-if="$can('document_line_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Document Line
                  </v-btn>
                </template> -->
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Description -->
                          <!-- <v-col cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.description"
                              :rules="descriptionRules"
                              label="Description"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Description <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Qty -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.qty"
                              :rules="qtyRules"
                              label="Qty"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Qty
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Price -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.price"
                              :rules="priceRules"
                              label="Price"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Price
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.tax"
                              :rules="taxRules"
                              label="Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total Before Tax -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.total_before_tax"
                              :rules="totalBeforeTaxRules"
                              label="Total Before Tax"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total Before Tax
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Total -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedDocumentLine.total"
                              :rules="totalRules"
                              label="Total"
                              type="number"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Total
                                  <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col> -->

                          <!-- Categories -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="categoryName"
                              :items="categoryNamesList"
                              :rules="categoryNameRules"
                              attach
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Category <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Document Id -->
                          <v-col class="mt-n5" cols="12">
                            <v-select
                              v-model="editedDocumentLine.document_id"
                              :items="documentIdsList"
                              :rules="documentIdRules"
                              attach
                              label="Document Id"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Document Id <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this document
                    line?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteDocumentLineConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <!-- <v-icon
              v-if="$can('document_line_update')"
              small
              class="mr-2"
              @click="editDocumentLine(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon> -->
            <v-icon
              v-if="$can('document_line_delete')"
              small
              @click="deleteDocumentLine(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Document Lines per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementDocumentLines",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      dateMenu: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        // { text: "Description", value: "description" },
        // { text: "Qty", value: "qty" },
        // { text: "Price", value: "price" },
        // { text: "Tax", value: "tax" },
        // { text: "Total Before Tax", value: "total_before_tax" },
        // { text: "Total", value: "total" },
        { text: "Document Id", value: "document_id" },
        { text: "Category Id", value: "category_id" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      documentLines: [],
      categories: [],

      categoryName: "",
      categoryNamesList: [],

      documentId: null,
      documentIdsList: [],

      editedIndex: -1,
      editedDocumentLine: {
        id: 0,
        description: "description",
        qty: 0,
        price: 0,
        tax: 0,
        total_before_tax: 0,
        total: 0,
        document_id: 0,
        category_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultDocumentLine: {
        id: 0,
        description: "description",
        qty: 0,
        price: 0,
        tax: 0,
        total_before_tax: 0,
        total: 0,
        document_id: 0,
        category_id: 0,
        created_at: "",
        updated_at: "",
      },

      // description
      descriptionRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 255 || "Can not be more than 255 characters",
      ],

      // qty
      qtyRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Qty must contain only 0 to 9",
        (v) => (v && v >= 0) || "Qty should be 0 or above",
        (v) => v.length <= 255 || "Qty can not be more than 255 characters",
      ],

      // price
      priceRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Price must contain only 0 to 9",
        (v) => (v && v >= 0) || "Price should be 0 or above",
        (v) => v.length <= 255 || "Price can not be more than 255 characters",
      ],

      // tax
      taxRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Tax should be 0 or above",
        (v) => v.length <= 255 || "Tax can not be more than 255 characters",
      ],

      // total before tax
      totalBeforeTaxRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[0-9\s]+$/.test(v) || "Total Before Tax must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total Before Tax should be 0 or above",
        (v) =>
          v.length <= 255 ||
          "Total Before Tax can not be more than 255 characters",
      ],

      // total
      totalRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9\s]+$/.test(v) || "Total must contain only 0 to 9",
        (v) => (v && v >= 0) || "Total should be 0 or above",
        (v) => v.length <= 255 || "Total can not be more than 255 characters",
      ],

      // categoryName
      categoryNameRules: [(v) => !!v || "Required"],

      // document id
      documentIdRules: [(v) => !!v || "Required"],

      page: 1,
      documentLinesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1
        ? "New Document Line"
        : "Edit Document Line";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedDocumentLines();
    },

    // documentLinesPerPage
    documentLinesPerPage() {
      this.getApiPagedDocumentLines();
    },

    // perPageChoice
    perPageChoice(val) {
      this.documentLinesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("document_line_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // this.getApiDocumentLines();
    this.getApiPagedDocumentLines();

    this.getApiCategories();
    this.getApiDocuments();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiDocumentLine();
        } else {
          this.createApiDocumentLine();
        }
      }
    },

    // editDocumentLine
    editDocumentLine(documentLine) {
      this.editedIndex = this.documentLines.indexOf(documentLine);
      this.editedDocumentLine = Object.assign({}, documentLine);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.categoryName = this.$helpers.getNameByIdFromList(
          this.categories,
          documentLine.category_id
        );
      }

      if (this.editedIndex !== -1) {
        this.$refs.form.resetValidation();
      }
    },

    // deleteDocumentLine
    deleteDocumentLine(documentLine) {
      this.editedIndex = this.documentLines.indexOf(documentLine);
      this.editedDocumentLine = Object.assign({}, documentLine);
      this.dialogDelete = true;
    },

    // deleteDocumentLineConfirm
    deleteDocumentLineConfirm() {
      this.deleteApiDocumentLine();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedDocumentLine = Object.assign({}, this.defaultDocumentLine);
        this.editedIndex = -1;
        this.categoryName = "";
      });

      this.$refs.form.reset();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedDocumentLine = Object.assign({}, this.defaultDocumentLine);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api Document Lines
    async getApiDocumentLines() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("documentlines");

      if (res.status == 200) {
        this.documentLines = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged DocumentLines
    async getApiPagedDocumentLines() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `documentlines/paged?page_no=${this.page}&page_size=${this.documentLinesPerPage}`
      );

      if (res.status == 200) {
        this.documentLines = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Document Line
    async createApiDocumentLine() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "documentlines",
        {
          description: this.editedDocumentLine.description,
          qty: parseInt(this.editedDocumentLine.qty),
          price: parseInt(this.editedDocumentLine.price),
          tax: parseInt(this.editedDocumentLine.tax),
          total_before_tax: parseInt(this.editedDocumentLine.total_before_tax),
          total: parseInt(this.editedDocumentLine.total),
          category_id: this.$helpers.getIdByNameFromList(
            this.categories,
            this.categoryName
          ),
          document_id: parseInt(this.editedDocumentLine.document_id),
        },
        "Document Line Created Successfully!"
      );

      if (res.status == 200) {
        this.documentLines.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Document Line
    async updateApiDocumentLine() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `documentlines/${this.editedDocumentLine.id}`,
        {
          id: this.editedDocumentLine.id,
          description: this.editedDocumentLine.description,
          qty: parseInt(this.editedDocumentLine.qty),
          price: parseInt(this.editedDocumentLine.price),
          tax: parseInt(this.editedDocumentLine.tax),
          total_before_tax: parseInt(this.editedDocumentLine.total_before_tax),
          total: parseInt(this.editedDocumentLine.total),
          category_id: this.$helpers.getIdByNameFromList(
            this.categories,
            this.categoryName
          ),
          document_id: parseInt(this.editedDocumentLine.document_id),
        },
        "Document Line Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.documentLines[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Document Line
    async deleteApiDocumentLine() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `documentlines/${this.editedDocumentLine.id}`,
        "Document Line Deleted Successfully!"
      );

      if (res.status == 200) {
        this.documentLines.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Categories
    async getApiCategories() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("categories");

      if (res.status == 200) {
        this.categories = res.data;

        this.categoryNamesList = this.$helpers.getNamesFromList(
          this.categories
        );
      }

      this.overlay = false;
    },

    // Get Api Documents
    async getApiDocuments() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("documents");

      if (res.status == 200) {
        this.documents = res.data;

        for (var document of this.documents) {
          this.documentIdsList.push(document.id);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
